.chart-legend li span{
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.chart-legend{
height:250px;
overflow:auto;
}

.chart-legend li{
cursor:pointer;
}