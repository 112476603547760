.mySwiper {
  position: relative;
  /* height: 100%; */
}

.image-swiper-button {
  cursor: pointer;

  position: absolute;
  top: calc(50% - 1.5rem);
  height: 3rem;
  width: 3rem;

  z-index: 10;
  margin: 0;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border-radius: 1.5rem;
  align-content: center;
  text-align: center;
}

.image-swiper-button-next {
  right: -1.5rem;
}

.image-swiper-button-prev {
  left: -1.5rem;
}

.swiper-button-disabled {
  opacity: 0.5;
}

.image-swiper-button-content {
  height: 100%;
  vertical-align: center;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
}

.image-swiper-button-content-right {
  justify-content: start;
}

.image-swiper-button-content-left {
  justify-content: end;
}
