.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  right: 28px !important;
  bottom: 105px !important;
}
/* .grecaptcha-badge:hover {
    width: 220px !important;
    } */
